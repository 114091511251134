<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter"
      style="height: 90vh">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-row v-if="documentRow.length > 0">
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
              striped
              small
              id="myTabless"
              bordered
              selectable
              selected-variant="primary"
              select-mode="single"
              responsive="sm"
              :items="documentRow"
              :fields="field"
              @row-dblclicked="test"
              @row-clicked="clicked"
              @row-contextmenu="handleContextMenuEvent"
              head-row-variant="secondary">
              <template #cell(checkbox)="row">
                <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                </b-form-checkbox>
              </template>
              <template #cell(category)="data">
                {{ data.item.category === "payment" ? "Пополнение" : "Списание" }}
              </template>
              <template #cell(performer_id)="data">
                <div @dblclick="getDrivers(data)" v-if="data.item.performer_id" class="reference-field">
                  {{ data.item.performer_id }}
                </div>
              </template>
              <template #cell(order_id)="data">
                <div @dblclick="getHistory(data)" class="reference-field">
                  {{ data.item.order_id }}
                </div>
              </template>
              <template #cell(performer_login)="data">
                <div @dblclick="getDriversLogin(data)" v-if="data.item.performer_login" class="reference-field">
                  {{ data.item.performer_login }}
                </div>
              </template>
            </b-table>
            <vue-context ref="menu">
              <li>
                <b-link class="d-flex align-items-center" v-b-modal="$route.name + 'CreateModal'">
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="ml-75">Добавить</span>
                </b-link>
                <b-link class="d-flex align-items-center" v-b-modal.cancelModal>
                  <feather-icon icon="align-justify" size="16" />
                  <span class="ml-75">Отмена</span>
                </b-link>
              </li>
            </vue-context>
            <b-modal id="cancelModal" size="sm" title="Отменить" no-close-on-backdrop hide-footer no-enforce-focus>
              <b-row>
                <b-col cols="12">
                  <label for="">Комментарий <i style="color: red">*</i></label>
                  <b-form-textarea v-model="cancelDocument.comment"></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col class="d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    class="mr-1"
                    size="sm"
                    :disabled="cancelDocument.comment ? false : true"
                    @click="cancelDoc()">
                    Ок
                  </b-button>
                  <b-button size="sm" @click="close()"> Отмена </b-button>
                </b-col>
              </b-row>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    <modal-payments @refresh="refresh"></modal-payments>
  </div>
</template>

<script>
import modalPayments from "@/views/component/Modal/ModalDocument/modalPayments.vue";
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    modalPayments,
    VueContext,
    ToastificationContent,
  },
  data() {
    return {
      drivers: undefined,
      order_id: this.$route.query.order_id,
      assigment: [],
      showPreloader: false,
      refreshTimer: null,
      documentRow: [],
      field: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        {
          key: "category_id",
          label: "ID",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "performer_id",
          label: "ID исполнителя",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "order_id",
          label: "ID заказа",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "sum_after",
          label: "Сумма после",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "amount",
          label: "Сумма",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "sum_for",
          label: "Сумма до",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "comment",
          label: "Комментарий",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "category",
          label: "Категория",
          sortable: true,
          thStyle: { width: "160px" },
        },
        {
          key: "type_name",
          label: "Имя типа",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Дата",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "creataed_user",
          label: "Создавший сотрудник",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      fields: [
        // {key: 'division', label: 'Подраздиления'},
        { key: "sum_for", label: "Сумма до" },
        { key: "sum_after", label: "Сумма после" },
        { key: "amount", label: "Сумма" },
        { key: "transaction_category", label: "Категория" },
        { key: "type_name", label: "Имя типа" },
        { key: "performer_login", label: "Логин" },
        { key: "comment", label: "Коментарий" },
        { key: "created_user", label: "Создавший сотрудник" },
        { key: "date_time", label: "Дата" },
      ],
      cancelDocument: {
        category_id: undefined,
        category: undefined,
        comment: undefined,
      },
    };
  },
  methods: {
    getHistory(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.order_id,
        `/order/order-history`,
        data.item.order_id,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.order_id },
      });
    },
    resizeable() {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(document.querySelectorAll("table th"), function (th) {
        th.style.position = "relative";
        var grip = document.createElement("div");
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = "5px";
        grip.style.position = "absolute";
        grip.style.cursor = "col-resize";
        grip.addEventListener("mousedown", function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });
        th.appendChild(grip);
      });
      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });
      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.cancelDocument.category_id = item.category_id;
      this.cancelDocument.category = item.category;
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      this.orderId = item.id;
      let allTd = document.querySelectorAll("td");
      allTd.forEach((el) => {
        el.style.backgroundColor = "";
        el.style.color = "";
      });
      let td = event.target;
      if (event.target.tagName.toLowerCase() === "td") {
        td.style.backgroundColor = "#2979ff";
        td.style.color = "#fff";
      } else if (event.target.tagName.toLowerCase() === "span") {
        let span = event.target;
        span.parentNode.style.backgroundColor = "#2979ff";
        span.parentNode.style.color = "#fff";
      }
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`).then((res) => {
        this.documentRow = res.data;
        this.$store.commit("pageData/setdataCount", this.documentRow.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    test() {
      let division_id = JSON.parse(localStorage.getItem("userData"));
    },
    sendToParent(tableData) {
      this.documentRow = tableData;
      this.$store.commit("pageData/setdataCount", this.documentRow.length);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.documentRow = res.data;
          this.$store.commit("pageData/setdataCount", this.documentRow.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    cancelDoc() {
      this.$http
        .post("billing/payment-document/payment-cancel", this.cancelDocument)
        .then((res) => {
          this.close();
          this.refresh();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Баланс отменен`,
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    close() {
      this.cancelDocument = {
        category_id: undefined,
        category: undefined,
        comment: undefined,
      };
      this.$bvModal.hide("cancelModal");
    },
    getDrivers(data) {
      this.$router.push({
        name: "infoDriver",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/infoDriver`,
        data.item.performer_id,
      ]);
    },
    getDriversLogin(data) {
      this.$router.push({
        name: "infoLogin",
        query: { filter: data.item.performer_login },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/infoLogin`,
        data.item.performer_login,
      ]);
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.resizeable();
    this.refreshTimer = setInterval(this.resizeable, 300);
    this.openFilter();
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.documentRow = res.data;
          this.$store.commit("pageData/setdataCount", this.documentRow.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
